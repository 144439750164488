<template>
  <section class="title-image-carousel">
    <div class="carousel">
      <input
        v-for="(slide, index) in block.data"
        :key="'title-image-carousel-nav' + index"
        class="carousel-locator"
        :id="'slide-' + (index + 1)"
        type="radio"
        name="title-image-slider"
        hidden=""
        checked=""
      />
      <div class="carousel-container">
        <figure v-for="(slide, index) in block.data" :key="'title-image-slide-' + index" class="carousel-item">
          <label class="item-prev btn btn-action btn-lg" :for="'slide-' + navigation[index].previous"><span class="icon svg-left"></span></label>
          <label class="item-next btn btn-action btn-lg" :for="'slide-' + navigation[index].next"><span class="icon svg-right"></span></label>
          <img :src="slide.img.src" :srcset="slide.img.srcset" :sizes="slide.img.sizes" :alt="slide.img.alt" :title="slide.img.title" :width="slide.img.width" :height="slide.img.height" />
          <figcaption>
            <router-link :to="slide.path">
              <h2 class="title">{{ slide.title }}</h2>
              <span v-if="slide.subtitle" class="subtitle">{{ slide.subtitle }}</span>
            </router-link>
          </figcaption>
        </figure>
      </div>
      <div class="carousel-nav hide-xs">
        <label v-for="(slide, index) in block.data" :key="'title-image-carousel-nav2-' + index" class="nav-item text-hide c-hand" :for="'slide-' + (index + 1)">{{ index }}</label>
      </div>
    </div>
  </section>
</template>

<style>
.title-image-carousel .carousel-container:before {
  padding-bottom: 33.18%; /* TODO This should be calculated By The Image dimentions */
}
.title-image-carousel img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}
.title-image-carousel figcaption {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>

<script>
export default {
  name: 'BlockUriTitleImageCarousel',
  props: {
    block: Object,
  },
  computed: {
    navigation() {
      let navigation = [];
      this.block.data.forEach((slide, index) => {
        index++; // Base 1
        let buttons = { previous: index - 1, next: index + 1 };
        if (index == 1) {
          buttons.previous = this.block.data.length;
        }
        if (index == this.block.data.length) {
          buttons.next = 1;
        }
        navigation.push(buttons);
      });
      return navigation;
    },
  },
  data() {
    return {};
  },
};
</script>
